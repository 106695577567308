<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container fluid >
      <v-card :loading="loading">
        <v-card-title class="pb-0"
          ><div class="oc_filter_main_div">
            <v-col class="pb-0 pl-0 pt-0">
              <div
                style="
                  width: 240px;
                  display: inline-block;
                  text-align: start;
                  margin-top: 6px;
                "
              >
                <v-autocomplete
                  :items="camera_name_object"
                  :menu-props="{ offsetOverflow: true }"
                  v-model="selected_cameras"
                  filled
                  label="Camera"
                  chips
                  class="assign_select mr-2 standin_label_select assign_select_v font_400"
                  height="32"
                  outlined
                  dense
                  rounded
                  small-chips
                  hide-details=""
                  deletable-chips
                  multiple
                  :full-width="true"
                  :allow-overflow="false"
                  ><template v-slot:selection="{ item, index }">
                    <v-chip
                      close
                      small
                      v-if="index === 0 && item"
                      @click:close="selected_cameras = []"
                    >
                      <span>{{ selected_cameras.length }} Cameras</span>
                    </v-chip>
                  </template></v-autocomplete
                >
              </div>
              <div
                style="
                  display: inline-block;
                  text-align: start;
                  margin-top: 4px;
                "
              >
                <v-menu
                  :close-on-content-click="false"
                  open-on-click
                  offset-overflow
                  v-if="date_range[0]"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="date_range"
                      color="grey"
                      class="assign_select mr-2 mt-1 filter_button font_400"
                      height="32"
                      style="bottom: 2px"
                      rounded
                      outlined
                    >
                      <!-- <v-icon @click="change_date(false)" class="mr-2 pl-1 ml-2"
                        >mdi-arrow-left</v-icon
                      > -->
                      <span
                        style="text-transform: none"
                        v-bind="attrs"
                        v-on="on"
                        >{{ display_date }}</span
                      >
                      <!-- <v-icon
                        :disabled="date_range == todays_date"
                        @click="change_date(true)"
                        class="ml-2 pr-1 mr-2"
                        
                        >mdi-arrow-right</v-icon
                      > -->
                    </v-btn>
                  </template>

                  <v-card height="380" width="340">
                    <v-row>
                      <v-col style="text-align: center" class="pb-0">
                        <v-date-picker
                          v-model="date_range"
                          flat
                          no-title
                          range
                          id="video_dp"
                          event-color="#ff9900"
                          :max="todays_date"
                          :min="min_date"
                        ></v-date-picker>
                      </v-col>
                      <v-col style="text-align: center" class="pt-0">
                        <span style="width: 300px; display: inline-block"
                          ><time-range-picker
                            v-model="time_range"
                            dense
                            hide-details
                            :hideWholeDayCheckbox="true"
                            hide-selected
                            outlined
                            step="30"
                        /></span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-menu>

                <!--   <v-btn
                  style="display: inline-block; bottom: 2px"
                  class="assign_select mt-1 filter_button"
                  height="32"
                  @click="update_sort_direction"
                  rounded
                  outlined
                  color="grey"
                >                  <v-icon v-if="sort_direction == 'DESC'" color="grey darken-2"
                    >mdi-sort-clock-ascending-outline</v-icon
                  >
                  <v-icon v-else color="grey darken-2"
                    >mdi-sort-clock-descending-outline</v-icon
                  ></v-btn
                > -->
              </div>
              <v-menu :close-on-content-click="false" offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :disabled="
                      image_filters.Estrus || image_filters.Contraction
                    "
                    rounded
                    outlined
                    color="grey"
                    class="assign_select filter_button font_400 mr-2"
                    height="32"
                  >
                    Classification <v-icon>mdi-menu-down</v-icon>
                    <v-chip
                      v-if="PGIE_count > 0"
                      style="pointer-events: none"
                      class="ml-4"
                      small
                      >{{ PGIE_count }}</v-chip
                    >
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="classification in classification_list"
                    :key="'PGIE_' + classification"
                  >
                    <v-checkbox
                      hide-details
                      class="mt-0"
                      :label="classification"
                      v-model="image_filters.classification[classification]"
                      @change="select_filter('PGIE', classification)"
                    >
                    </v-checkbox>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu :close-on-content-click="false" offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    rounded
                    :disabled="
                      image_filters.Estrus || image_filters.Contraction
                    "
                    outlined
                    color="grey"
                    class="assign_select filter_button font_400 mr-2"
                    height="32"
                  >
                    Species <v-icon>mdi-menu-down</v-icon>
                    <v-chip
                      v-if="PGIE_count > 0"
                      style="pointer-events: none"
                      class="ml-4"
                      small
                      >{{ PGIE_count }}</v-chip
                    >
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="classification in PGIE_type_list"
                    :key="'PGIE_' + classification"
                  >
                    <v-checkbox
                      hide-details
                      class="mt-0"
                      :label="classification"
                      v-model="image_filters.PGIE[classification]"
                      @change="select_filter('PGIE', classification)"
                    >
                    </v-checkbox>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-menu :close-on-content-click="false" offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    rounded
                    outlined
                    color="grey"
                    class="assign_select filter_button font_400 mr-2"
                    height="32"
                    :disabled="
                      loading ||
                      image_filters.Estrus ||
                      image_filters.Contraction
                    "
                  >
                    Activity <v-icon>mdi-menu-down</v-icon>
                    <v-chip
                      v-if="Activity_count > 0"
                      style="pointer-events: none"
                      class="ml-2"
                      small
                      >{{ Activity_count }}</v-chip
                    >
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-checkbox
                      hide-details
                      class="mt-0"
                      label="Standing/Walking"
                      v-model="image_filters.Activity['standing_walking']"
                      @change="select_filter('Activity', 'standing_walking')"
                    >
                    </v-checkbox>
                  </v-list-item>
                  <v-list-item>
                    <v-checkbox
                      hide-details
                      class="mt-0"
                      label="Lying down"
                      v-model="image_filters.Activity['lying_down']"
                      @change="select_filter('Activity', 'lying_down')"
                    >
                    </v-checkbox>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-menu :close-on-content-click="false" offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    rounded
                    outlined
                    color="grey"
                    class="assign_select filter_button font_400 mr-2"
                    height="32"
                    :disabled="
                      loading ||
                      image_filters.Estrus ||
                      image_filters.Contraction
                    "
                  >
                    Age <v-icon>mdi-menu-down</v-icon>
                    <v-chip
                      v-if="Age_count > 0"
                      style="pointer-events: none"
                      class="ml-2"
                      small
                      >{{ Age_count }}</v-chip
                    >
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-checkbox
                      hide-details
                      class="mt-0"
                      label="Adult"
                      v-model="image_filters.Age['adult']"
                      @change="select_filter('Age', 'adult')"
                    >
                    </v-checkbox>
                  </v-list-item>
                  <v-list-item>
                    <v-checkbox
                      hide-details
                      class="mt-0"
                      label="Calf"
                      v-model="image_filters.Age['calf']"
                      @change="select_filter('Age', 'calf')"
                    >
                    </v-checkbox>
                  </v-list-item>
                </v-list>
              </v-menu>

              <!-- <v-menu :close-on-content-click="false" offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    rounded
                    outlined
                    color="grey"
                    class="assign_select filter_button font_400 mr-2"
                    height="32"
                  >
                    Estrus <v-icon>mdi-menu-down</v-icon>
                    <v-chip
                      v-if="estrus_count > 0"
                      style="pointer-events: none"
                      class="ml-2"
                      small
                      >{{ estrus_count }}</v-chip
                    >
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="estrus in estrus_list"
                    :key="estrus"
                  >
                    <v-checkbox
                      hide-details
                      class="mt-0"
                      :label="estrus"
                      v-model="image_filters.Estrus[estrus]"
                      @change="select_filter('estrus', estrus)"
                    >
                    </v-checkbox>
                  </v-list-item>
                </v-list>
              </v-menu> -->

              <v-btn
                text
                color="grey"
                class="assign_select filter_button font_400 pr-0"
                height="32"
                :disabled="loading || image_filters.Contraction"
              >
                Estrus
                <v-checkbox
                  hide-details
                  class="mt-0 ml-2"
                  label=""
                  v-model="image_filters.Estrus"
                  @change="select_filter('Estrus')"
                >
                </v-checkbox>
              </v-btn>
              <v-btn
                text
                color="grey"
                class="assign_select filter_button font_400"
                height="32"
                :disabled="loading || image_filters.Estrus"
              >
                Calving
                <v-checkbox
                  hide-details
                  class="mt-0 ml-2"
                  label=""
                  v-model="image_filters.Contraction"
                  @change="select_filter('Contraction')"
                >
                </v-checkbox>
              </v-btn>

              <!-- <v-btn
                text
                color="grey"
                class="assign_select filter_button font_400"
                height="32"
                :disabled="loading"
              >
                Estrus
                <v-checkbox
                  hide-details
                  class="mt-0 ml-2"
                  label=""
                  v-model="image_filters.Mounting"
                  @change="select_filter('Mounting')"
                >
                </v-checkbox>
              </v-btn> -->
            </v-col>

            <!-- <v-select
                  class="assign_select assign_select_v ml-2 font_400 pagination_select"
                  height="32"
                  outlined
                  dense
                  rounded
                  :items="page_limits"
                  :menu-props="{ offsetY: true }"
                  :hide-details="true"
                  v-model="per_page"
                ></v-select> -->
            <div style="float: right; width: 190px">
              <div style="width: 86px; float: left">
                <v-select
                  class="assign_select assign_select_v font_400 pagination_select mt-1"
                  height="32"
                  v-model="per_row"
                  :items="row_limits"
                  outlined
                  dense
                  rounded
                  hide-details
                  :disabled="loading"
                ></v-select>
              </div>

              <div style="float: right" class="mt-1">
                <v-tooltip color="black" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      @click="refresh"
                      :disabled="loading"
                      class="mb-1"
                      >mdi-refresh</v-icon
                    >
                  </template>
                  <div>Refresh</div>
                </v-tooltip>

                <v-tooltip color="black" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon
                        @click="show_bbox = !show_bbox"
                        class="mb-1"
                        v-if="!show_bbox"
                        :disabled="loading"
                        >mdi-drawing-box</v-icon
                      >
                      <v-icon
                        @click="show_bbox = !show_bbox"
                        class="mb-1"
                        v-else
                        color="primary"
                        :disabled="loading"
                        >mdi-drawing-box</v-icon
                      ></span
                    >
                  </template>
                  <div v-if="!show_bbox">Show bounding boxes</div>
                  <div v-else>Hide bounding boxes</div>
                </v-tooltip>

                <!-- <v-icon
                  @click="set_borders(true)"
                  v-if="!bordered"
                  :disabled="loading"
                  >mdi-border-all-variant</v-icon
                >
                <v-icon
                  @click="set_borders(true)"
                  v-else
                  color="primary"
                  :disabled="loading"
                  >mdi-border-all-variant</v-icon
                > -->

                <v-tooltip color="black" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon
                        @click="show_text = !show_text"
                        class="mb-1"
                        v-if="!show_text"
                        :disabled="loading"
                        >mdi-format-text</v-icon
                      >
                      <v-icon
                        @click="show_text = !show_text"
                        class="mb-1"
                        v-else
                        color="primary"
                        :disabled="loading"
                        >mdi-format-text</v-icon
                      ></span
                    >
                  </template>
                  <div v-if="!show_text">Show image info</div>
                  <div v-else>Hide image info</div>
                </v-tooltip>

                <v-tooltip color="black" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon
                        @click="show_hover = !show_hover"
                        class="mb-1"
                        :color="show_hover ? 'primary' : null"
                        :disabled="loading"
                        dense
                      >
                        mdi-cursor-default
                      </v-icon></span
                    >
                  </template>
                  <div v-if="!show_hover">Enable zoom on hover</div>
                  <div v-else>Disable zoom on hover</div>
                </v-tooltip>
              </div>
            </div>
          </div>
        </v-card-title>

        <!-- image list -->
        <v-row
          class="ml-0 mr-0 mt-4 px-3 pb-3"
          v-if="loading || image_paths.length"
        >
          <div id="grid-container-lf">
            <image-thumbnail
              v-for="(p, index) in image_paths"
              :key="p.image_path + '_' + index"
              :img="p"
              :img_index="index"
              :show_text="show_text"
              :show_hover="show_hover"
              :bordered="selected_image_index === index"
              :image_filters="image_filters"
              :show_bbox="show_bbox"
            ></image-thumbnail>
          </div>
          <div class="skeleton_container">
            <v-skeleton-loader
              v-if="more_images"
              v-intersect.quiet="nextPage"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </div>
        </v-row>

        <v-row class="ma-auto" justify="center" v-else>
          <v-col cols="12"><h1 class="no_data">No Images</h1></v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-dialog
      v-model="open_image"
      :max-width="showSideContainers ? `1440px` : '1000px'"
      style="margin: 0"
      ><v-card style="min-width: 1000px" v-if="image_paths && image_paths[selected_image_index]"
        ><v-card-title>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            "
          >
            <div>{{ get_time(image_paths[selected_image_index]) }}</div>

            <div style="flex: 1; text-align: center">
              {{ customer_information }}
            </div>

            <div style="display: flex; align-items: center">
              <div
                @click="
                  nav_to_table(image_paths[selected_image_index].camera_id)
                "
                style="
                  color: grey;
                  font-size: 18px;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                "
              >
                <v-icon>mdi-cctv</v-icon>
                {{
                  cameras_object[image_paths[selected_image_index].camera_id]
                }}
              </div>

              <v-icon
                :disabled="selected_image_index == 0"
                @click="selected_image_index -= 1"
                style="margin-left: 8px"
                >mdi-arrow-left</v-icon
              >
              <v-icon
                :disabled="selected_image_index == image_paths.length - 1"
                @click="selected_image_index += 1"
                style="margin-left: 8px"
                >mdi-arrow-right</v-icon
              >
              <v-icon
                large
                v-if="fullscreen_img"
                @click="fullscreen_img = false"
                style="margin-left: 8px"
                >mdi-fullscreen-exit</v-icon
              >
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <div style="position: relative">
            <image-inf
              :img_src="[
                [
                  {
                    img: image_paths[selected_image_index],
                    image_index: selected_image_index,
                  },
                ],
                cookie_bucket +
                  get_path(image_paths[selected_image_index]) +
                  '_event.jpg',
              ]"
            />
            <!-- <span
              id="fullscreen_notification_img"
              @click="fullscreen_img = true"
              ><v-icon style="height: 100%; width: 100%" large
                >mdi-fullscreen</v-icon
              ></span
            >
            <span id="download_notification_img">
              <a
                :href="
                  cookie_bucket +
                  image_paths[selected_image_index].image_path +
                  '_event.jpg'
                "
                style="height: 100%; width: 100%"
                download
              >
                <v-icon style="height: 100%; width: 100%">mdi-download</v-icon>
              </a></span
            > -->
          </div>
        </v-card-text>
      </v-card></v-dialog
    >
    <!-- <image-card></image-card> -->
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
// import VueLoadImage from "vue-load-image";
import moment from "moment";
//import ImageCard from "../components/ImageCard.vue";
import ImageThumbnail from "../components/ImageThumbnail.vue";
import { eventBus } from "@/main.js";
import JSZip from "jszip";
import ImageInf from "@/components/ImageInf.vue";
import TimeRangePicker from "vuetify-time-range-picker";

export default {
  components: {
    navbar,
    sidenav,
    ImageInf,
    TimeRangePicker,
    // "vue-load-image": VueLoadImage,

    ImageThumbnail,
  },
  props: ["filter"],
  data() {
    return {
      showSideContainers: window.innerWidth >= 1470,
      selected_image_index: false,
      fullscreen_img: false,
      loading: false,
      per_page: 64,
      page_limits: [48, 64, 80, 96, 112],
      per_row: 6,
      row_limits: [2, 4, 6, 8, 12],
      show_text: true,
      show_bbox: true,
      show_hover: true,
      selected_cameras: [],
      bordered: false,
      date_range: [],
      time_range: {},
      image_filters: {
        PGIE: {},
        Contraction: false,
        Mounting: false,
        Activity: {},
        Age: {},
        classification: {},
        Estrus: false,
      },
      open_image: false,
      PGIE_count: 0,
      estrus_count: 0,
      SGIE_count: 0,
      Contraction_count: 0,
      Activity_count: 0,
      Age_count: 0,
      Suckling_count: 0,
      todays_date: moment().format("YYYY-MM-DD"),
      classification_list: ["animal", "person", "vehicle"],
      estrus_list: ["mounting", "chinrest", "heat"],
      PGIE_type_list: [
        "cat",
        "cow",
        "horse",
        "pig",
        "sheep",
        "canine",
        "bear",
        "bison",
      ],
      SGIE_type_list: ["head", "tail"],
      image_type_list: ["contraction", "predator", "drop tag"],
      model_version_list: [
        "pgie_yolox_ensemble",
        "sgie_dog_activity",
        "sgie_cow_suckling",
        "sgie_keypoint_0_ensemble",
        "sgie_cow_body_part_ensemble",
        "sgie_cow_age",
        "sgie_contraction",
        "sgie_dog_body_part_ensemble",
        "sgie_cow_ocr",
        "sgie_cow_activity",
      ],
      more_images: false,

      // model versions
      model_versions: {},

      selected_images: [],
      check_all_icon: "mdi-checkbox-multiple-blank-outline",
      sort_direction: "DESC",

      min_date: moment().subtract(89, "d").format("YYYY-MM-DD"),
    };
  },
  methods: {
    checkScreenWidth() {
      this.showSideContainers = window.innerWidth >= 1470;
    },
    nav_to_table(device_id) {
      this.open_notification = false;
      this.$router.push({
        name: "videos",
        params: {
          cameraname: device_id,
        },
      });
    },
    get_date(date) {
      return moment(date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD hh:mm A");
    },
    weekday(date) {
      var dayofweek;
      if (moment(date, "YYYY-MM-DD").weekday() == 0) {
        dayofweek = "Sun";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 1) {
        dayofweek = "Mon";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 2) {
        dayofweek = "Tue";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 3) {
        dayofweek = "Wed";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 4) {
        dayofweek = "Thur";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 5) {
        dayofweek = "Fri";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 6) {
        dayofweek = "Sat";
      }
      return dayofweek;
    },
    // change_date(next) {
    //   if (next) {
    //     this.date_range = moment(this.date_range, "YYYY-MM-DD")
    //       .add(1, "days")
    //       .format("YYYY-MM-DD");
    //   } else {
    //     this.date_range = moment(this.date_range, "YYYY-MM-DD")
    //       .subtract(1, "days")
    //       .format("YYYY-MM-DD");
    //   }
    // },
    update_sort_direction() {
      if (this.sort_direction == "DESC") {
        this.sort_direction = "ASC";
      } else {
        this.sort_direction = "DESC";
      }
    },
    get_path(img) {
      var year = img.media_timestamp.substr(0, 4);
      var month = img.media_timestamp.substr(5, 2);
      var day = img.media_timestamp.substr(8, 2);
      var full_date = img.media_timestamp
        .replaceAll(":", "-")
        .replace("T", "_");
      return `${img.customer_id}/${img.camera_id}/${year}/${month}/${day}/${img.camera_id}_${full_date}`;
    },
    get_time(img) {
      var image_time = moment(img.media_timestamp);
      return image_time.format("YYYY-MM-DD h:mm:ss A");
    },
    selectAllIcon() {
      if (this.check_all_icon != "mdi-checkbox-multiple-blank-outline") {
        this.check_all_icon = "mdi-checkbox-multiple-blank-outline";
      } else {
        this.check_all_icon = "mdi-checkbox-multiple-marked";
      }
      eventBus.$emit("select_all_images");
    },
    async downloadAllJSONs() {
      this.loading = true;

      var zip = JSZip();
      for (let i = 0; i < this.selected_images.length; i++) {
        zip = await this.fetchJSON(zip, this.selected_images[i]);
      }

      zip.generateAsync({ type: "blob" }).then(
        (blob) => {
          this.downloadBlob(blob, "JSONExport.zip");
          this.loading = false;
        },
        (err) => {
          console.log(err);
          this.loading = false;
        }
      );
    },
    fetchJSON(zip, image_path) {
      var url = process.env.VUE_APP_COOKIE_BUCKET + image_path + ".json.zip";
      return new Promise((resolve) => {
        fetch(url, { credentials: "include" })
          .then((res) => {
            return res.blob();
          })
          .then((data) => {
            return zip.loadAsync(data);
          })
          .then((zip) => {
            resolve(zip);
          });
      });
    },
    downloadBlob(blob, filename) {
      var blobUrl = URL.createObjectURL(blob);
      var link = document.createElement("a");
      link.href = blobUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    select_image(i) {
      console.log(i);
    },
    select_filter(model, classification) {
      for (let key in this.image_filters[model]) {
        if (key != classification) {
          this.image_filters[model][key] = false;
        }
      }

      var f_count = 0;
      for (let key in this.image_filters[model]) {
        if (this.image_filters[model][key]) {
          f_count++;
        }
      }
      this[`${model}_count`] = f_count;
    },

    initializeDataRange() {
      var date = moment().utc();
      var now = date.format("YYYY-MM-DD");
      var temp_date_range = [];
      temp_date_range[1] = now;
      temp_date_range[0] = moment(now, "YYYY-MM-DD")
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      this.date_range = temp_date_range;
    },
    refresh() {
      this.fetchImagePaths();
    },
    fetchImagePaths(load_more) {
      if (this.query_params) {
        var temp_query_params = { ...this.query_params };
        temp_query_params.load_more = load_more;
        this.loading = true;
        this.$store
          .dispatch("DDB_GET_IMAGES", temp_query_params)
          .then((more_images) => {
            this.more_images = more_images;
            this.loading = false;
          });
      }
    },
    age(date) {
      if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "seconds"
        ) < 60
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "seconds"
          ) +
            " " +
            "s",
          this.bordered ? "lf_green_active" : "lf_green",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "minutes"
        ) < 60
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "minutes"
          ) +
            " " +
            "m",
          this.bordered ? "lf_green_active" : "lf_green",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "hours"
        ) < 24
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "hours"
          ) +
            " " +
            "h",
          this.bordered ? "lf_blue_active" : "lf_blue",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "days"
        ) < 30
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "days"
          ) +
            " " +
            "D",
          this.bordered ? "lf_yellow_active" : "lf_yellow",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "months"
        ) < 12
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "months"
          ) +
            " " +
            "M",
          this.bordered ? "lf_orange_active" : "lf_orange",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "years"
        ) < 365
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "years"
          ) +
            " " +
            "Y",
          this.bordered ? "lf_orange_active" : "lf_orange",
        ];
      } else {
        return [false, false];
      }
    },
    nextPage(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.fetchImagePaths(true);
      }
    },
    set_borders(upd_bor) {
      if (upd_bor) {
        this.bordered = !this.bordered;
      }
      if (!this.bordered) {
        document.querySelectorAll(".lf_green_active").forEach(function (cl) {
          cl.classList.replace("lf_green_active", "lf_green");
        });
        document.querySelectorAll(".lf_blue_active").forEach(function (cl) {
          cl.classList.replace("lf_blue_active", "lf_blue");
        });
        document.querySelectorAll(".lf_yellow_active").forEach(function (cl) {
          cl.classList.replace("lf_yellow_active", "lf_yellow");
        });
        document.querySelectorAll(".lf_orange_active").forEach(function (cl) {
          cl.classList.replace("lf_orange_active", "lf_orange");
        });
      } else {
        document.querySelectorAll(".lf_green").forEach(function (cl) {
          cl.classList.replace("lf_green", "lf_green_active");
        });
        document.querySelectorAll(".lf_blue").forEach(function (cl) {
          cl.classList.replace("lf_blue", "lf_blue_active");
        });
        document.querySelectorAll(".lf_yellow").forEach(function (cl) {
          cl.classList.replace("lf_yellow", "lf_yellow_active");
        });
        document.querySelectorAll(".lf_orange").forEach(function (cl) {
          cl.classList.replace("lf_orange", "lf_orange_active");
        });
      }
    },
    updatePerRow() {
      var container = document.getElementById("grid-container-lf");
      container.style["grid-template-columns"] = `repeat(${this.per_row}, 1fr)`;
    },
  },
  watch: {
    per_row() {
      this.updatePerRow();
    },
    open_image() {
      if (!this.open_image) {
        this.selected_image_index = false;
      }
    },
    customer_id() {
      this.$store.dispatch("DDB_VIDEOS_AT_DATE").then(
        (response) => {
          var temp_date_range = [];
          temp_date_range[0] = response[0];
          // temp_date_range[0] = moment(response[0], "YYYY-MM-DD")
          //   .subtract(1, "days")
          //   .format("YYYY-MM-DD");
          this.time_range = { start: "00:00", end: "24:00" };
          this.date_range = temp_date_range;
        },
        (error) => {
          console.error(
            "Got nothing from server. Prompt user to check internet connection and try again",
            error
          );
        }
      );
    },

    query_params: {
      handler() {
        this.more_images = false;
        this.fetchImagePaths();
      },
      deep: true,
    },
  },
  computed: {
    customer_information() {
      if (this.$store.getters.getUserDDB) {
        let customer = this.$store.getters.getUserDDB;
        return `${customer.first_name} - ${customer.last_name}`;
      } else {
        return "";
      }
    },
    customer_id() {
      return this.$store.getters.getCustomerID;
    },
    query_params() {
      if (this.customer_id && this.date_range[0]) {
        var date_range = null;
        var temp_date_range = [...this.date_range].sort();
        if (this.date_range && this.timezeone_offset) {
          var selected_date = [];

          selected_date = [
            moment
              .tz(
                temp_date_range[0] + "T" + this.time_range.start,
                "YYYY-MM-DDTHH:mm",
                this.timezeone_offset
              )
              .utc()
              .format("YYYY-MM-DDTHH:mm"),
            moment
              .tz(
                temp_date_range[temp_date_range.length - 1] +
                  "T" +
                  this.time_range.end,
                "YYYY-MM-DDTHH:mm",
                this.timezeone_offset
              )
              .utc()
              .format("YYYY-MM-DDTHH:mm"),
          ];
          //  if (selected_date[0].slice(8, 10) != this.date_range[0].slice(8, 10)) {
          //    selected_date[0] = moment(selected_date[0], "YYYY-MM-DDThh")
          //      .add(1, "days")
          //      .format("YYYY-MM-DDThh");
          //  selected_date[1] = moment(selected_date[1], "YYYY-MM-DDThh")
          //      .add(1, "days")
          //      .format("YYYY-MM-DDThh");
          //  }
          date_range = selected_date;
        }
        var params = {
          limit: this.per_page,
          date_range: date_range,
          filters: this.image_filters,
          model_versions: this.model_versions,
          user_id: this.$store.getters.getCustomerID,
          camera_id: this.selected_cameras,
          sort: this.sort_direction,
        };

        return params;
      }
      return undefined;
    },
    display_date() {
      if (this.date_range[0] && this.date_range[1]) {
        this.date_range[0] + " " + this.date_range[1];
      }
      return (
        this.date_range[0] +
        " " +
        this.time_range.start +
        "-" +
        this.time_range.end
      );
    },
    cameras_object() {
      let cams = {};
      if (this.$store.getters.getCameraObjects) {
        for (const i in this.$store.getters.getCameraObjects) {
          cams[this.$store.getters.getCameraObjects[i].id] =
            this.$store.getters.getCameraObjects[i].name;
        }
      }
      return cams;
    },
    camera_name_object() {
      var cam_nicknames = [];
      if (this.$store.getters.getCameraObjects) {
        for (const i in this.$store.getters.getCameraObjects) {
          cam_nicknames.push({
            value: this.$store.getters.getCameraObjects[i].id,
            text: this.$store.getters.getCameraObjects[i].name,
          });
        }
      }
      return cam_nicknames;
    },
    timezeone_offset() {
      if (this.$store.getters.getSites && this.$store.getters.getSites[0]) {
        return this.$store.getters.getSites[0].timezone;
      }
      return "UTC";
    },

    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET;
    },
    image_paths() {
      if (this.$store.getters.getImages) {
        return this.$store.getters.getImages;
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.checkScreenWidth();
    window.addEventListener("resize", this.checkScreenWidth);
    window.addEventListener("keydown", (e) => {
      if (this.open_image) {
        if (
          e.key == "ArrowRight" &&
          this.selected_image_index < this.image_paths.length - 1
        ) {
          this.selected_image_index += 1;
        } else if (e.key == "ArrowLeft" && this.selected_image_index > 0) {
          this.selected_image_index -= 1;
        }
      }
    });
  },

  destroyed() {
    this.$store.dispatch("DDB_GET_IMAGES", "empty");
  },
  created() {
    eventBus.$on("open_dialog_images", (params) => {
      this.selected_image_index = params.image_index;
      this.open_image = true;
    });
    // this.PGIE_type_list.forEach((e) => {
    //   if (e == "cow") {
    //     this.image_filters.PGIE[e] = true;
    //   } else {
    //     this.image_filters.PGIE[e] = false;
    //   }
    // });
    //this.initializeDataRange();

    if (this.customer_id) {
      this.$store.dispatch("DDB_VIDEOS_AT_DATE").then(
        (response) => {
          var temp_date_range = [];
          temp_date_range[0] = response[0];
          // temp_date_range[0] = moment(response[0], "YYYY-MM-DD")
          //   .subtract(1, "days")
          //   .format("YYYY-MM-DD");
          this.time_range = { start: "00:00", end: "24:00" };
          this.date_range = temp_date_range;
        },
        (error) => {
          console.error(
            "Got nothing from server. Prompt user to check internet connection and try again",
            error
          );
        }
      );
    }

    eventBus.$on("previousImage", (index) => {
      index = index - 1;
      if (index < 0) {
        index = this.image_paths.length - 1;
      }
      var img = this.image_paths[index];
      var params = {
        img: img,
        image_index: index,
      };
      eventBus.$emit("open_dialog_images", params);
    });

    eventBus.$on("nextImage", (index) => {
      index = index + 1;
      if (index >= this.image_paths.length) {
        index = 0;
      }
      var img = this.image_paths[index];
      var params = {
        img: img,
        image_index: index,
      };
      eventBus.$emit("open_dialog_images", params);
    });
  },
};
</script>
<style lang="css" scoped>
.filter_button {
  font-size: 15px;
  letter-spacing: inherit !important;
  text-transform: none;
}
#lf_btns {
  /* width: 75px !important; */
  display: flex;
  float: right;
  margin-right: 6px;
  margin-top: 5px;
}
.skeleton_container {
  width: 100%;
}
.lf_green_active {
  border: 2px solid green;
}
.lf_blue_active {
  border: 2px solid rgb(0, 0, 220);
}
.lf_yellow_active {
  border: 2px solid rgb(220, 220, 0);
}
.lf_orange_active {
  border: 2px solid orange;
}
#grid-container-lf {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
}
.grid_container_f {
  position: relative;
  text-align: center;
}
/* .grid_container_f:hover {
  transform: scale(1.3);
  box-shadow: 0 0 10px black;
  z-index: 2 !important;
} */
.grid_container_f:hover > .datetime_grid {
  z-index: 3 !important;
}
.grid_img_f {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.darker_bckrnd {
  background-color: rgba(0, 0, 0, 0.377);
  padding-left: 1px;
  padding-right: 1px;
}
.datetime_grid {
  top: 0px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 12px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cam_id_grid {
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 10px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ago_grid {
  text-align: left;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-size: 11px;
  width: 95%;
  user-select: none;
  /* background-color: rgba(0, 0, 0, 0.377); */
}
.user_id_grid {
  position: absolute;
  top: 36px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 9px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.image_filter_menu {
  z-index: 101 !important;
}
</style>
